import React from 'react';

import { Icon, Link, VStack } from '@/components';
import { useTranslation } from '@/hooks';
import SellersIcon from '@/svgs/dashboard/drawer/sellers-icon.svg';
import TopAgentsIcon from '@/svgs/dashboard/drawer/top-agents.svg';

const BoardLinks: React.FC = () => {
  const { t } = useTranslation('components.staticsData');

  const boards = [
    {
      link: `${process.env.NEXT_PUBLIC_EVENT_BOARDS}/sellers-event?utm_source=liateam&utm_medium=menu&utm_campaign=mordad03bs`,
      title: t('sellers'),
      color: '#FFCCDC',
      icon: SellersIcon,
    },
    {
      link: `${process.env.NEXT_PUBLIC_EVENT_BOARDS}/top-agents`,
      title: t('topAgents'),
      color: '#CBC9FE',
      icon: TopAgentsIcon,
    },
  ];
  return (
    <VStack mt={10} gap={4} w="full" px={5}>
      {boards.map((item) => (
        <Link
          position="relative"
          key={item.title}
          maxW={96}
          w="full"
          href={item.link}
          borderRadius="curved"
          target="_blank"
          prefetch={false}
          bg={item.color}
          color="text"
          fontSize="sm"
          textAlign="center"
          py={3}
          fontWeight="medium"
          h="44px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={4}
        >
          <Icon as={item.icon} h="full" w="auto" />
          {item.title}
        </Link>
      ))}
    </VStack>
  );
};

export default BoardLinks;
