import React from 'react';
import { Box } from '@chakra-ui/react';

const PasswordStrength: React.FC<{ password: string }> = ({ password }) => {
  const [colors, setColors] = React.useState<string[]>([
    'gray.400',
    'gray.400',
    'gray.400',
    'gray.400',
  ]);

  const checkList = [
    password && /(?=.*[A-Z])/.test(password),
    password && /(?=.*[a-z])/.test(password),
    password && /(?=.*[0-9])/.test(password),
    password && /(?=.*[!@#$&*])/.test(password),
  ];

  const checkCount = checkList.filter((x) => Boolean(x)).length;

  React.useEffect(() => {
    const newColors = colors.map((color, i) => {
      if (i < checkCount) {
        const security: Record<number, string> = {
          1: 'error.500',
          2: 'warning.500',
          3: 'success.500',
          4: 'success.500',
        };
        if (password.length < 8) {
          return 'error.500';
        }
        return security[checkCount];
      }
      return 'gray.400';
    });

    setColors(newColors);
  }, [password]);

  return (
    <Box my={1} w="100%" display="flex" alignItems="center" gap={5}>
      <Box display="flex" alignItems="center" w="100%" gap={1}>
        {[0, 1, 2, 3].map((item) => {
          return (
            <Box key={item} h={1} w="25%" borderRadius={5} bg={colors[item]} />
          );
        })}
      </Box>
    </Box>
  );
};

export default PasswordStrength;
