import React from 'react';
import Cropper from 'react-easy-crop';
import { Plus } from 'react-feather';

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@/components';
import {
  useDisclosure,
  useProfile,
  useTranslation,
  useUploadAvatar,
} from '@/hooks';
import EditAvatar from '@/svgs/edit-avatar.svg';
import { getCroppedImage, standaloneToast } from '@/utils';

type CroppedProps = {
  x: number;
  y: number;
  width: number;
  height: number;
};

type EditAvatarModalProps = { avatar?: string };

const EditAvatarModal: React.FC<EditAvatarModalProps> = ({ avatar }) => {
  const { t } = useTranslation('components.dashboard.drawer.editAvatar');
  const { isOpen, onClose, onOpen } = useDisclosure();

  const inputRef = React.useRef<HTMLInputElement>(null);
  const [src, setSrc] = React.useState<string>(avatar || '');
  const [{ zoom, crop }, setInteractOnImage] = React.useState<{
    zoom: number;
    crop: Pick<CroppedProps, 'x' | 'y'>;
  }>({
    zoom: 1,
    crop: {
      x: 0,
      y: 0,
    },
  });
  const [croppedArea, setCroppedArea] = React.useState<CroppedProps>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const { mutateAsync: uploadAvatar, isPending } = useUploadAvatar();
  const { refetch } = useProfile();

  const onCropComplete = (_: CroppedProps, croppedAreaPixels: CroppedProps) => {
    setCroppedArea(croppedAreaPixels);
  };

  const handleSubmitProfileImage = async () => {
    try {
      const croppedImage = await getCroppedImage(src, croppedArea);
      if (croppedImage) {
        void uploadAvatar({ avatar: croppedImage }).then(async () => {
          await refetch();
          onClose();
        });
      }
    } catch {
      standaloneToast({ status: 'error', title: t('uploadIssue') });
    }
  };

  return (
    <Stack
      pos="absolute"
      bottom={0}
      left="50%"
      transform="translate(-50%, 50%)"
      cursor="pointer"
    >
      <Stack onClick={onOpen}>
        <EditAvatar />
      </Stack>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="lg">{t('modalTitle')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody minH="400px">
            {Boolean(src) && (
              <Cropper
                image={src}
                crop={crop}
                zoom={zoom}
                aspect={4 / 4}
                onCropChange={(location) =>
                  setInteractOnImage((prev) => ({
                    ...prev,
                    crop: { ...location },
                  }))
                }
                onCropComplete={onCropComplete}
                onZoomChange={(z) =>
                  setInteractOnImage((prev) => ({ ...prev, zoom: z }))
                }
                style={{
                  containerStyle: {
                    width: '100%',
                    height: '400px',
                    marginTop: '50px',
                  },
                }}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Stack
              w="100%"
              flexDir={{ base: 'column-reverse', md: 'row' }}
              align="center"
              justify="space-between"
              gap={{ base: 4, md: 0 }}
            >
              <input
                type="file"
                ref={inputRef}
                onChange={(value) =>
                  setSrc(
                    URL.createObjectURL(value.currentTarget.files?.[0] as Blob)
                  )
                }
                style={{ display: 'none' }}
              />
              <Button
                color="tertiary.500"
                variant="ghost"
                colorScheme="purple"
                leftIcon={<Plus />}
                onClick={() => void inputRef.current?.click()}
              >
                {t('selectNewPicture')}
              </Button>
              <Stack
                flexDir="row"
                align="center"
                justify="center"
                gap={{ base: 16, md: 4 }}
              >
                <Button variant="outline" colorScheme="gray" onClick={onClose}>
                  {t('close')}
                </Button>
                <Button
                  colorScheme="green"
                  onClick={() => void handleSubmitProfileImage()}
                  isLoading={isPending}
                  isDisabled={!src}
                >
                  {t('save')}
                </Button>
              </Stack>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default EditAvatarModal;
