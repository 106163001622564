import i18n from '@/i18n';
import { standaloneToast } from '@/utils';

type ContentType = {
  title?: string;
  url?: string;
};

type handleShareType = (arg?: {
  copyOnly: boolean;
}) => (props: ContentType) => Promise<void>;

const useShare: handleShareType = (arg = { copyOnly: false }) => {
  const { copyOnly } = arg;
  const copyToClipboard = async (url = '') => {
    await navigator.clipboard.writeText(url);
    standaloneToast({
      status: 'success',
      title: i18n.t('utils.useShare.copiedToClipboard'),
    });
  };

  const handleShare = async ({ title, url }: ContentType) => {
    if (navigator.share) {
      try {
        if (copyOnly) {
          await copyToClipboard(url);
        } else {
          await navigator.share({ title, url });
        }
      } catch {
        await copyToClipboard(url);
      }
    } else {
      await copyToClipboard(url);
    }
  };

  return handleShare;
};

export default useShare;
