import type {
  DiscountProductCode,
  IErrorResponse,
  ResponseDiscountProductCode,
} from '@/types';

import { useMutation, type UseMutationResult } from '@tanstack/react-query';

import { urls } from '@/statics';
import { api } from '@/utils';

const useDiscountProductCode = (): UseMutationResult<
  ResponseDiscountProductCode,
  IErrorResponse,
  DiscountProductCode
> =>
  useMutation({
    mutationFn: (data: DiscountProductCode) =>
      api.post(urls.discountProductCode, data),
  });

export default useDiscountProductCode;
