import * as yup from 'yup';

const mixed = {
  default: '${label} نامعتبر است',
  required: '${label} الزامی است',
  oneOf: '${label} باید یکی از این موارد باشد: ${values}',
  notOneOf: '${label} نمی‌تواند یکی از این موارد باشد: ${values}',
  notType: '${label} باید عدد باشد',
};
const string = {
  length: '${label} باید ${length} کاراکتر باشد',
  min: '${label} حداقل باید ${min} کاراکتر باشد',
  max: '${label} حداکثر ${max} کاراکتر باشد',
  matches: '${label} باید مطابق این الگو باشد: "${regex}"',
  email: '${label} نامعتبر است',
  url: '${label} باید یک URL معتبر باشد',
  uuid: '${label} باید یک UUID معتبر باشد',
  trim: 'قبل و بعد ${label} نمیتواند فاصله داشته باشد',
  lowercase: '${label} باید با حروف کوچک باشد',
  uppercase: '${label} باید با حروف بزرگ باشد',
};

const number = {
  min: '${label} حداقل باید ${min} رقم باشد',
  max: '${label} حداکثر ${max} رقم باشد',
  lessThan: '${label} باید کمتر از  ${less} باشد',
  moreThan: '${label} باید بیشتر از  ${more} باشد',
  notEqual: '${label} نمی‌تواند ${notEqual} باشد',
  positive: '${label} باید یک مقدار مثبت باشد',
  negative: '${label} باید یک مقدار منفی باشد',
  integer: '${label} باید عدد باشد',
};

const date = {
  min: 'تاریخ ${label} باید بعد از ${min} باشد',
  max: 'تاریخ ${label} باید قبل از ${max} باشد',
};

const boolean = {};

const object = {
  noUnknown: '${label} دارای مقادیر ناشناخته است: ${unknown}',
};

const array = {
  min: '${label} حداقل باید ${min} مورد باشد',
  max: '${label} حداکثر باید ${min} مورد باشد',
};

yup.setLocale({
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
});

export default yup;
