import type { InputPropTypes } from './types';

import React from 'react';
import { FormHelperText, Input as CkInput } from '@chakra-ui/react';

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  PasswordStrength,
  Spinner,
} from '@/components';
import Close from '@/svgs/Close.svg';
import EyeOff from '@/svgs/EyeOff.svg';
import EyeOn from '@/svgs/EyeOn.svg';
import Search from '@/svgs/Search.svg';

const Input = React.forwardRef<HTMLInputElement, InputPropTypes>(
  (
    {
      isLeftToRight,
      error,
      label,
      isSearch,
      isPassword,
      onClear,
      value,
      isRequired = false,
      isClearable = true,
      isLoading = false,
      hasHint = false,
      helperText = '',
      ...rest
    },
    forwardedRef
  ) => {
    const [show, setShow] = React.useState(false);
    const ref = React.useRef<HTMLInputElement>(null);

    return (
      <FormControl isInvalid={Boolean(error)} isRequired={isRequired}>
        {label && <FormLabel>{label}</FormLabel>}
        <InputGroup ref={ref}>
          <CkInput
            dir={isLeftToRight ? 'ltr' : 'unset'}
            ref={forwardedRef}
            type={!show && isPassword ? 'password' : 'text'}
            value={value}
            textAlign="start"
            {...rest}
          />
          {onClear && !isPassword && isClearable && (
            <InputRightElement h="100%" aria-label="clear">
              {value && (
                <IconButton
                  onClick={() => {
                    ref?.current?.getElementsByTagName('input')[0].focus();
                    onClear();
                  }}
                  colorScheme="gray"
                  aria-label="clear"
                  variant="ghost"
                  icon={<Icon boxSize="6" as={Close} stroke={'gray.700'} />}
                />
              )}
            </InputRightElement>
          )}
          {isSearch && !error && (
            <InputLeftElement h="100%" aria-label="search">
              {isLoading ? (
                <InputLeftElement h="100%">
                  <Spinner color="gray.700" size="sm" />
                </InputLeftElement>
              ) : (
                <Icon boxSize="6" as={Search} stroke="gray.700" />
              )}
            </InputLeftElement>
          )}
          {isPassword && (
            <InputRightElement
              h="100%"
              onClick={() => setShow(!show)}
              aria-label="password"
              right={0}
            >
              <IconButton
                onClick={() => setShow(!show)}
                aria-label="show password"
                variant="ghost"
                colorScheme="gray"
                icon={
                  show ? (
                    <Icon boxSize="6" stroke="gray.700" as={EyeOn} />
                  ) : (
                    <Icon boxSize="6" as={EyeOff} />
                  )
                }
              />
            </InputRightElement>
          )}
        </InputGroup>
        {hasHint && (
          <PasswordStrength password={value as unknown as string} {...rest} />
        )}
        {helperText && (
          <FormHelperText maxW="full">{helperText}</FormHelperText>
        )}
        <FormErrorMessage message={error || ''} />
      </FormControl>
    );
  }
);

Input.displayName = 'Input';
export default Input;
