import type {
  IErrorResponse,
  NotificationType,
  PaginationResponse,
} from '@/types';

import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import queryString from 'query-string';

import { urls } from '@/statics';

const usePopups = ({
  profile,
  isRead,
}: {
  profile: boolean;
  isRead?: boolean;
}): UseQueryResult<PaginationResponse<NotificationType>, IErrorResponse> =>
  useQuery({
    queryKey: [
      `${urls.notifications}?${queryString.stringify({ is_read: isRead })}`,
    ],
    enabled: profile,
  });

export default usePopups;
