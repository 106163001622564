import type { IErrorResponse } from '@/types';

import { useMutation, type UseMutationResult } from '@tanstack/react-query';

import { useTranslation } from '@/hooks';
import { urls } from '@/statics';
import { api, standaloneToast } from '@/utils';

const useLogout = (): UseMutationResult<unknown, IErrorResponse, unknown> => {
  const { t } = useTranslation('messages');

  return useMutation({
    mutationFn: (data) => api.post(urls.logout, data),
    onSuccess() {
      standaloneToast({
        status: 'success',
        title: t('logoutFromYourAccount'),
      });
    },
  });
};

export default useLogout;
