/* eslint-disable no-nested-ternary */
import React from 'react';
import { Form, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/navigation';

import {
  Box,
  Button,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from '@/components';
import {
  useDiscountProductCode,
  useInputAttributes,
  useTranslation,
} from '@/hooks';
import NoResult from '@/images/header/noResult.png';
import { jalaliDayjs, yupValidator } from '@/utils';

type PromotionCodeType = {
  code: string;
};

const PromotionCodeActivationModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const { t } = useTranslation('components.header');
  const {
    mutateAsync: sendFileDiscountProductCode,
    isPending: sendFileLoading,
    isSuccess,
    data,
    isError,
  } = useDiscountProductCode();
  const router = useRouter();

  const validationSchema = yupValidator.object().shape({
    code: yupValidator
      .string()
      .label(t('promotionCode'))
      .max(8)
      .min(8)
      .required(),
  });
  const {
    handleSubmit,
    control,
    register,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const filedInputProps = useInputAttributes<PromotionCodeType>(
    control,
    register,
    errors,
    {
      code: t('promotionCodeActivation'),
    }
  );
  const onSubmit = (sendingData: PromotionCodeType) => {
    void sendFileDiscountProductCode(sendingData).then(() => router.refresh());
  };
  const Congrats = dynamic(
    () => import('../PromotionCodeActivationModalAnimation'),
    {
      ssr: false,
    }
  );

  return (
    <>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent
          w="650px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          minH={{ lg: '340px' }}
          pos="relative"
        >
          <Box zIndex="modal">
            <ModalCloseButton fill="black" />
          </Box>
          {isSuccess && (
            <>
              <Box
                h={{ base: '105px', lg: '140px' }}
                w={{ base: '105px', md: '140px' }}
                pos="absolute"
                bottom={-15}
                left={-15}
              >
                <Congrats />
              </Box>
              <Box
                h={{ base: '105px', lg: '140px' }}
                w={{ base: '105px', md: '140px' }}
                pos="absolute"
                bottom={-15}
                transform="scaleX(-1)"
                right={-15}
              >
                <Congrats />
              </Box>
            </>
          )}

          <ModalBody
            p={8}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {isSuccess ? (
              <Stack
                justifyContent="center"
                w={{ base: '230px', lg: '320px' }}
                h={{ base: '130px', lg: '145px' }}
              >
                <Stack
                  p="17px 38px"
                  gap="23px"
                  bgColor="grayAlpha.400"
                  borderRadius="8px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text color="text" fontWeight={700} fontSize="22px">
                    {t('congrats')}
                  </Text>
                  <Text
                    color="text"
                    fontWeight={500}
                    fontSize="md"
                    textAlign="center"
                  >
                    {t('thePromotionWasActivated', {
                      title: data.discount_product.title,
                      date: jalaliDayjs(data.end_at, { jalali: false }).format(
                        'MM/DD'
                      ),
                    })}
                  </Text>
                </Stack>
              </Stack>
            ) : isError ? (
              <>
                <Stack justifyContent="center" alignItems="center">
                  <Text
                    color="text"
                    fontSize={{ base: 'md', md: '22px' }}
                    fontWeight={700}
                  >
                    {t('sorry')}
                  </Text>
                  <Text
                    color="text"
                    fontSize={{ base: 'sm', md: 'md' }}
                    fontWeight={500}
                  >
                    {t('invalidCode')}
                  </Text>
                  <Box w={{ base: '155px', lg: '225px' }}>
                    {' '}
                    <Image src={NoResult} alt="invalid code" />
                  </Box>
                </Stack>
              </>
            ) : (
              <Stack w={{ base: '100%', md: '276px' }}>
                <Form
                  control={control}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 16,
                    paddingBlock: 20,
                  }}
                >
                  <Input
                    variant="outline"
                    autoFocus
                    type="text"
                    {...filedInputProps.code}
                    placeholder={t('promotionCode')}
                  />
                  <Stack justifyContent="center" alignItems="center" w="100%">
                    <Button
                      isLoading={sendFileLoading}
                      type="submit"
                      colorScheme="secondary"
                      onClick={(payload) =>
                        void handleSubmit(onSubmit)(payload)
                      }
                    >
                      {t('applyCode')}
                    </Button>
                  </Stack>
                </Form>
              </Stack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PromotionCodeActivationModal;
