import type {
  Control,
  FieldErrors,
  FieldValues,
  Path,
  UseFormRegister,
  UseFormRegisterReturn,
} from 'react-hook-form';

type IUseInputAttributesReturn<T extends FieldValues = FieldValues> = Record<
  keyof T,
  {
    name: string;
    id: string;
    label: string;
    control: Control<T>;
    error: string | undefined;
  } & UseFormRegisterReturn
>;

const useInputAttributes = <T extends FieldValues = FieldValues>(
  control: Control<T>,
  register: UseFormRegister<T>,
  errors: FieldErrors<T>,
  fields: Record<keyof T, string>
): IUseInputAttributesReturn<T> => {
  return Object.fromEntries(
    Object.entries(fields).map(([key, label]) => [
      key,
      {
        ...register(key as Path<T>),
        name: key,
        id: key,
        label: label,
        control: control,
        error: errors[key]?.message,
      },
    ])
  ) as IUseInputAttributesReturn<T>;
};

export default useInputAttributes;
