import type { IModal } from './types';

import { Modal as CkModal } from '@chakra-ui/react';

const Modal: IModal = ({ children, ...props }) => {
  return (
    <CkModal scrollBehavior="inside" {...props}>
      {children}
    </CkModal>
  );
};

export default Modal;
