import type { GetExclusiveLinkResponse, IErrorResponse } from '@/types';

import { useQuery, type UseQueryResult } from '@tanstack/react-query';

import { urls } from '@/statics';

const useExclusiveLink = (): UseQueryResult<
  GetExclusiveLinkResponse,
  IErrorResponse
> => useQuery({ queryKey: [urls.getExclusiveLink], enabled: false });

export default useExclusiveLink;
