import type { IModalCloseButton } from './types';

import { ModalCloseButton as CkModalCloseButton } from '@chakra-ui/react';

import { Icon } from '@/components';
import Close from '@/svgs/Close.svg';

const ModalCloseButton: IModalCloseButton = () => {
  return (
    <CkModalCloseButton>
      <Icon as={Close} stroke="black" w="5" h="5" />
    </CkModalCloseButton>
  );
};

export default ModalCloseButton;
