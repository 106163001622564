import type { IFormErrorMessage } from './types';

import {
  FormErrorIcon,
  FormErrorMessage as CkFormErrorMessage,
  Icon,
} from '@chakra-ui/react';

import Close from '@/svgs/Close.svg';

const FormErrorMessage: IFormErrorMessage = ({ message }) => {
  return (
    <CkFormErrorMessage>
      <FormErrorIcon>
        <Icon as={Close} fill="transparent" />
      </FormErrorIcon>
      {message}
    </CkFormErrorMessage>
  );
};

export default FormErrorMessage;
