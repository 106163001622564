import type { StaticDataProps } from './data';

import React from 'react';
import { usePathname } from 'next/navigation';

import EditAvatarModal from '../EditAvatarModal';

import {
  BoardLinks,
  Box,
  Circle,
  Flex,
  HStack,
  Image,
  Link,
  LogoutModal,
  PromotionCodeActivationModal,
  Stack,
  Text,
} from '@/components';
import {
  useDisclosure,
  useExclusiveLink,
  usePopups,
  useProfile,
  useShare,
  useTranslation,
} from '@/hooks';
import BirthdayDesktopIcon from '@/images/anniversary/sidebar-birthday-desktop.png';
import BirthdayMobileIcon from '@/images/anniversary/sidebar-birthday-mobile.png';
import WeddingDesktopIcon from '@/images/anniversary/sidebar-wedding-desktop.png';
import WeddingMobileIcon from '@/images/anniversary/sidebar-wedding-mobile.png';
import Avatar from '@/images/dashboard/profile.png';
import { routes } from '@/statics';
import AddressesIcon from '@/svgs/dashboard/drawer/addresses.svg';
import AiIcon from '@/svgs/dashboard/drawer/ai.svg';
import AnalyticsIcon from '@/svgs/dashboard/drawer/analytics.svg';
import ArrowDown from '@/svgs/dashboard/drawer/arrowDown.svg';
import ArrowTop from '@/svgs/dashboard/drawer/arrowTop.svg';
import ArrowUDownLeft from '@/svgs/dashboard/drawer/ArrowUDownLeft.svg';
import CommissionIcon from '@/svgs/dashboard/drawer/commission.svg';
import ContactIcon from '@/svgs/dashboard/drawer/contacts.svg';
import DashboardIcon from '@/svgs/dashboard/drawer/dashboard.svg';
import FavoritesIcon from '@/svgs/dashboard/drawer/favorites.svg';
import LearningPortalIcon from '@/svgs/dashboard/drawer/learningPortal.svg';
import NotificationIcon from '@/svgs/dashboard/drawer/notification.svg';
import OfferingProductIcon from '@/svgs/dashboard/drawer/offeringProduct.svg';
import PersonalInfoIcon from '@/svgs/dashboard/drawer/personalInfo.svg';
import MyLinkIcon from '@/svgs/dashboard/drawer/personalLink.svg';
import PromotionCode from '@/svgs/dashboard/drawer/promotionCode.svg';
import StepsToSuccessIcon from '@/svgs/dashboard/drawer/promotionNextMonth.svg';
import PurchaseRecordsIcon from '@/svgs/dashboard/drawer/purchaseRecordsIcon.svg';
import SendingTicketIcon from '@/svgs/dashboard/drawer/sendingTicket.svg';
import SimulationIcon from '@/svgs/dashboard/drawer/simulationIcon.svg';
import TreeIcon from '@/svgs/dashboard/drawer/tree.svg';

const StaticData: StaticDataProps = ({ isDashboardPage, onClose }) => {
  const { t } = useTranslation('components.dashboard.drawer');
  const [showMore, setShowMore] = React.useState<boolean>(false);
  const { data: profile } = useProfile();
  const pathname = usePathname();
  const { data: popups } = usePopups({
    profile: Boolean(profile),
    isRead: false,
  });
  const { refetch } = useExclusiveLink();
  const handleShare = useShare();
  const {
    isOpen: isOpenPromoCode,
    onOpen: onOpenPromoCode,
    onClose: onClosePromoCode,
  } = useDisclosure();
  const isAgent = profile?.user_type === 'agent';
  const isSuspended = profile?.is_suspended;
  const hasRollback = profile?.has_rollback;

  const data = [
    {
      title: routes.dashboard.title,
      icon: <DashboardIcon />,
      link: routes.dashboard.url,
      type: 'internal',
      isVisible: true,
    },
    {
      title: t('promotionCode'),
      icon: <PromotionCode />,
      link: '',
      onClick: () => {
        onOpenPromoCode();
      },
      type: 'internal',
      isVisible: false,
    },
    {
      title: routes.nextMonthRetailDiscount.title,
      icon: <StepsToSuccessIcon />,
      link: routes.nextMonthRetailDiscount.url,
      type: 'internal',
      isVisible: isAgent && !isSuspended,
    },
    {
      title: routes.rollbacks.title,
      icon: <ArrowUDownLeft />,
      link: routes.rollbacks.url,
      type: 'internal',
      isVisible: hasRollback,
    },
    {
      title: t('myLink'),
      icon: <MyLinkIcon />,
      onClick: async () => {
        const { data: response } = await refetch();
        await handleShare({
          title: t('exclusiveLinkCopied'),
          url: response?.link,
        });
      },
      type: 'button',
      isVisible: isAgent,
    },
    {
      title: routes.tickets.title,
      icon: <SendingTicketIcon />,
      link: routes.tickets.url,
      type: 'internal',
      isVisible: true,
    },
    {
      title: routes.personalInformation.title,
      icon: <PersonalInfoIcon />,
      link: routes.personalInformation.url,
      type: 'internal',
      isVisible: true,
    },
    {
      title: routes.manageLprs.title,
      icon: <OfferingProductIcon />,
      link: routes.manageLprs.url,
      type: 'internal',
      isVisible: isAgent,
    },
    {
      title: t('learningPortal'),
      icon: <LearningPortalIcon />,
      link: process.env.NEXT_PUBLIC_LIATEAM_METS,
      type: 'external',
      isVisible: isAgent,
    },

    {
      title: t('tree'),
      icon: <TreeIcon />,
      link: process.env.NEXT_PUBLIC_TREE_URL || '/',
      type: 'external',
      isVisible: isAgent && !isSuspended,
    },
    {
      title: t('simulator'),
      icon: <SimulationIcon />,
      link: `${process.env.NEXT_PUBLIC_TREE_URL}?simulator=true`,
      type: 'external',
      isVisible: isAgent,
    },
    {
      title: routes.commission.title,
      icon: <CommissionIcon />,
      link: routes.commission.url,
      type: 'internal',
      isVisible: isAgent && !isSuspended,
    },
    {
      title: routes.orders.title,
      icon: <PurchaseRecordsIcon />,
      link: routes.orders.url,
      type: 'internal',
      isVisible: true,
    },
    {
      title: t('contacts'),
      icon: <ContactIcon />,
      link: process.env.NEXT_PUBLIC_CONTACTS_URL,
      type: 'external',
      isVisible: false,
    },
    {
      title: t('analytics'),
      icon: <AnalyticsIcon />,
      link: process.env.NEXT_PUBLIC_ANALYTICS_URL,
      type: 'external',
      isVisible: false,
    },
    {
      title: t('notifications'),
      icon: (
        <Box position="relative">
          {popups?.list && popups.list.length > 0 && (
            <Circle position="absolute" bg={'primary.500'} size="8px"></Circle>
          )}
          <NotificationIcon />
        </Box>
      ),
      link: routes.notifications.url,
      type: 'internal',
      isVisible: true,
    },
    {
      title: routes.favorites.title,
      icon: <FavoritesIcon />,
      link: routes.favorites.url,
      type: 'internal',
      isVisible: true,
    },
    {
      title: routes.addresses.title,
      icon: <AddressesIcon />,
      link: routes.addresses.url,
      type: 'internal',
      isVisible: true,
    },
    {
      title: routes.delaverse.title,
      icon: <AiIcon />,
      link: routes.delaverse.url,
      type: 'internal',
      isVisible: true,
    },
    {
      title: '',
      icon: <LogoutModal fontSize={'lg !important'} onConfirm={onClose} />,
      type: 'button',
      isVisible: true,
    },
  ];

  const toggleShowMore = (): void => {
    setShowMore(!showMore);
  };
  // eslint-disable-next-line consistent-return
  const compareLink = (path: string, link: string) => {
    if (path === link) {
      return true;
    }

    const dashboardIndex = link.indexOf('/dashboard/');

    if (dashboardIndex !== -1) {
      const afterDashboard = link.substring(
        dashboardIndex + '/dashboard/'.length
      );

      if (path.includes(`/${afterDashboard}`)) {
        return true;
      }
      return false;
    }
  };
  const LinkBox = ({
    item,
  }: {
    item: {
      title: string;
      icon: React.JSX.Element;
      link?: string;
      type: string;
      onClick?: () => void;
    };
  }) => {
    if (item.link) {
      return (
        <Link
          href={item.link}
          width="100%"
          paddingBlock="10px"
          mb="8px"
          target={item.type === 'external' ? '_blank' : '_self'}
          prefetch={item.type === 'internal'}
        >
          <Flex gap="37px" pr="26px" alignItems="center">
            <Box
              sx={{
                strokeWidth: '2',
                stroke:
                  // eslint-disable-next-line no-nested-ternary
                  item.type === 'internal' &&
                  compareLink(pathname, `${item.link}`)
                    ? 'primary.500'
                    : (item.type === 'external' || item.type === 'button') &&
                        pathname === `${item.link}`
                      ? 'primary.500'
                      : 'text',

                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '29px',
              }}
            >
              {item.icon}
            </Box>
            <Text
              fontSize="lg"
              color={
                // eslint-disable-next-line no-nested-ternary
                item.type === 'internal' &&
                compareLink(pathname, `${item.link}`)
                  ? 'primary.500'
                  : (item.type === 'external' || item.type === 'button') &&
                      pathname === `${item.link}`
                    ? 'primary.500'
                    : 'text'
              }
            >
              {item.title}
            </Text>
          </Flex>
        </Link>
      );
    }
    return (
      <Flex
        gap="37px"
        pr="28px"
        alignItems="center"
        cursor="pointer"
        width="100%"
        paddingBlock="10px"
        mb="8px"
        onClick={() => item.onClick?.()}
        justifyContent="flex-start"
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          sx={{
            strokeWidth: '2',
            stroke: pathname === `${item.link}` ? 'primary.500' : 'text',
          }}
          width={item.title ? '25px' : '100%'}
        >
          {item.icon}
        </Flex>
        {item.title && (
          <Text
            fontSize="lg"
            color={pathname === `${item.link}` ? 'primary.500' : 'text'}
          >
            {item.title}
          </Text>
        )}
      </Flex>
    );
  };

  return (
    <Stack pos="relative">
      <Stack
        pos="absolute"
        top={-20}
        left="50%"
        transform="translate(-50%, 0)"
        display={{ base: 'none', md: 'flex' }}
      >
        {profile?.is_birthdate_anniversary ? (
          <Image src={BirthdayDesktopIcon} alt="anniversary-icon" />
        ) : profile?.is_marriage_anniversary ? (
          <Image src={WeddingDesktopIcon} alt="anniversary-icon" />
        ) : (
          ''
        )}
      </Stack>
      {isOpenPromoCode && (
        <PromotionCodeActivationModal
          isOpen={isOpenPromoCode}
          onClose={onClosePromoCode}
        />
      )}
      <HStack mt="30.5px" pr="20px" gap="21px">
        <Stack pos="relative">
          <Stack
            borderRadius={50}
            w={14}
            h={14}
            align="center"
            justify="center"
            overflow="hidden"
          >
            <Image
              src={profile?.avatar || Avatar}
              alt="avatar"
              width={61}
              height={61}
            />
          </Stack>
          <EditAvatarModal avatar={profile?.avatar as string} />
        </Stack>
        <Box>
          <Flex gap="5px">
            <Text fontSize="lg">{profile?.first_name}</Text>
            <Text fontSize="lg">{profile?.last_name}</Text>
          </Flex>
          <Flex gap="5px">
            <Text color="gray.800">{t('code1212')}</Text>
            <Text>{profile?.public_id}</Text>
          </Flex>
        </Box>
        <Stack w="72px" display={{ base: 'flex', md: 'none' }} mr="auto">
          {profile?.is_birthdate_anniversary ? (
            <Image src={BirthdayMobileIcon} alt="anniversary-icon" />
          ) : profile?.is_marriage_anniversary ? (
            <Image src={WeddingMobileIcon} alt="anniversary-icon" />
          ) : (
            ''
          )}
        </Stack>
      </HStack>
      <HStack
        display={{ base: 'none', md: 'flex' }}
        mt="54px"
        mb="34px"
        flexDir="column"
        justifyContent="center"
        alignItems="start"
      >
        {data
          .filter((item) => Boolean(item.isVisible))
          .map((item, index) => (
            <Flex key={index} w="100%" alignItems="center">
              <LinkBox key={index} item={item} />
            </Flex>
          ))}
      </HStack>
      {!isDashboardPage && (
        <>
          {isAgent && <BoardLinks />}
          <HStack
            display={{ base: 'flex', md: 'none' }}
            mt={8}
            mb="26px"
            flexDir="column"
            justifyContent="flex-start"
            alignItems="start"
          >
            {data
              .filter((item) => Boolean(item.isVisible))
              .map((item, index) => (
                <Flex
                  key={index}
                  w="100%"
                  alignItems="center"
                  onClick={
                    item.title === '' || item.title === t('promotionCode')
                      ? undefined
                      : onClose
                  }
                >
                  <LinkBox key={index} item={item} />
                </Flex>
              ))}
          </HStack>
        </>
      )}
      {isDashboardPage && (
        <>
          {isOpenPromoCode && (
            <PromotionCodeActivationModal
              isOpen={isOpenPromoCode}
              onClose={onClosePromoCode}
            />
          )}
          <HStack
            display={{ base: 'flex', md: 'none' }}
            mt="54px"
            mb="34px"
            flexDir="column"
            justifyContent="center"
            alignItems="start"
          >
            {data
              .filter((item) => Boolean(item.isVisible))
              .slice(0, showMore ? data.length : Math.ceil(data.length / 2))
              .map((item, index) => (
                <Flex key={index} w="100%" alignItems="center">
                  <LinkBox key={index} item={item} />
                </Flex>
              ))}
          </HStack>
          <Box
            gap={3}
            justifyContent="center"
            alignItems="center"
            my={3}
            onClick={toggleShowMore}
            display={{ base: 'flex', md: 'none' }}
            cursor="pointer"
          >
            <Text fontSize="md">
              {showMore ? t('showLess') : t('showMore')}
            </Text>
            {showMore ? <ArrowTop /> : <ArrowDown />}
          </Box>
        </>
      )}
    </Stack>
  );
};

export default StaticData;
