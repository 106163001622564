import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { usePathname } from 'next/navigation';
import { useShallow } from 'zustand/react/shallow';

import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from '@/components';
import { useLogout, useTranslation } from '@/hooks';
import useIsAdmin from '@/hooks/store/useIsAdmin';
import { privateRoutPrefixes, routes } from '@/statics';
import SignOutOfAccountIcon from '@/svgs/dashboard/drawer/logOut.svg';
import { deleteCookie, deleteLocalStorage } from '@/utils';

const LogoutModal: React.FC<{
  fontSize?: string;
  icon?: boolean;
  onConfirm?: () => void;
}> = ({ fontSize, icon = true, onConfirm }) => {
  const { t } = useTranslation('components.header.logoutModal');
  const pathname = usePathname();

  const toggleUserType = useIsAdmin(
    useShallow((state) => state.toggleUserType)
  );

  const { onClose, isOpen, onOpen } = useDisclosure();
  const { mutateAsync: logout, isPending } = useLogout();
  const [navigateLoading, setNavigateLoading] = React.useState<boolean>(false);

  const isPrivateRoute = privateRoutPrefixes.some((routePrefix) =>
    pathname.startsWith(routePrefix)
  );

  return (
    <>
      <Button
        colorScheme="white"
        height="27px"
        padding="0"
        textColor="text"
        w="100%"
        onClick={onOpen}
        fontSize={fontSize ? fontSize : 'sm'}
        justifyContent={icon ? 'flex-start' : 'center'}
        gap={8}
      >
        {icon && <SignOutOfAccountIcon strokeWidth={'2'} stroke={'text'} />}
        {t('signOut')}
      </Button>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w="650px">
          <ModalBody p={8}>
            <Text textAlign="center" color="text">
              {t('logoutWarning')}
            </Text>
            <Stack
              flexDir="row"
              align="center"
              justify="center"
              marginTop="30px"
              gap="25px"
            >
              <Button onClick={onClose} colorScheme="gray" variant="outline">
                {t('cancel')}
              </Button>
              <Button
                isLoading={isPending || navigateLoading}
                colorScheme="secondary"
                onClick={() => {
                  setNavigateLoading(true);
                  void logout({})
                    .then(async () => {
                      await deleteCookie({ name: 'lia-token' });
                      deleteLocalStorage({ name: 'lia-token' });
                      toggleUserType(false);
                      if (isPrivateRoute) {
                        window.location.replace(routes.signIn.url);
                      } else {
                        window.location.replace(pathname);
                      }
                      onClose();
                      onConfirm?.();
                    })
                    .catch(() => setNavigateLoading(false));
                }}
              >
                {t('submit')}
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LogoutModal;
