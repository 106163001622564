import type { IErrorResponse, UploadAvatarRequest } from '@/types';

import { useMutation, type UseMutationResult } from '@tanstack/react-query';

import { useTranslation } from '@/hooks';
import { urls } from '@/statics';
import { api, standaloneToast } from '@/utils';

const useUploadAvatar = (): UseMutationResult<
  unknown,
  IErrorResponse,
  UploadAvatarRequest
> => {
  const { t } = useTranslation('messages');

  return useMutation({
    mutationFn: (data) => api.put(urls.uploadAvatar, data),
    onSuccess() {
      standaloneToast({
        status: 'success',
        title: t('yourImageChangeSuccessfully'),
      });
    },
  });
};

export default useUploadAvatar;
